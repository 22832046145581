import React from 'react';

const DashboardPreview: React.FC = () => {
  return (
    <section id="dashboard-preview" className="dashboard-preview">
      <h2>Intelligent Building Dashboard</h2>
      <div className="dashboard-grid dashboard-images">
        <div className="dashboard-item">
          <h3>Real-Time Occupancy</h3>
          <img src="/images/dashboard-realtime.png" alt="Real-Time Occupancy Dashboard" />
          <p>Monitor current space utilization with our intuitive floor plan view and occupancy metrics.</p>
        </div>
        <div className="dashboard-item">
          <h3>Historical Occupancy</h3>
          <img src="/images/dashboard-historical.png" alt="Historical Occupancy Dashboard" />
          <p>Analyze trends and patterns with detailed historical occupancy data and visualizations.</p>
        </div>
      </div>
    </section>
  );
};

export default DashboardPreview;