import React from 'react';
import logo from '../assets/senseai-logo.png';

const Header: React.FC = () => {
    return (
      <header className="main-header">
        <div className="header-content">
          <div className="logo-container">
            <img src={logo} alt="SenseAI Logo" className="logo" />
          </div>
          <nav>
            <ul>
              <li><a href="#home">Home</a></li>
              <li><a href="#solutions">Solutions</a></li>
              {/* <li><a href="#team">Team</a></li> */}
              <li><a href="#about">About</a></li>
              <li><a href="#contact">Contact</a></li>
            </ul>
          </nav>
        </div>
      </header>
    );
};

export default Header;