import React from "react";
import { Radar, Layers, Cpu, Map } from "lucide-react";

const Features: React.FC = () => {
  const features = [
    {
      icon: <Radar size={32} className="feature-icon" />,
      title: "Novel Sensing",
      description:
        "Advanced thermal sensing technology for precise human behavior detection.",
    },
    {
      icon: <Layers size={32} className="feature-icon" />,
      title: "Advanced Sensor Fusion",
      description:
        "Combining multiple sensor inputs for comprehensive spatial awareness.",
    },
    {
      icon: <Cpu size={32} className="feature-icon" />,
      title: "Efficient Edge AI",
      description:
        "On-device processing for real-time analysis and reduced data transmission.",
    },
    {
      icon: <Map size={32} className="feature-icon" />,
      title: "Spatial AI",
      description:
        "Intelligent space mapping and utilization analysis for optimized environments.",
    },
  ];

  return (
    <section className="features" id="features">
      <h2>Our Features</h2>
      <div className="feature-grid">
        {features.map((feature, index) => (
          <div className="feature-card" key={index}>
            <div className="feature-icon-wrapper">{feature.icon}</div>
            <h3>{feature.title}</h3>
            <p>{feature.description}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Features;
