import React from 'react';

const Contact: React.FC = () => {
  return (
    <section id="contact" className="contact">
      <h2>Contact Us</h2>
      <p>Get in touch with us for any inquiries or to request a demo:</p>
      <a href="mailto:contact@sense-ai.org" className="contact-email">contact@sense-ai.org</a>
    </section>
  );
};

export default Contact;