import React from 'react';
import Header from './components/Header';
import Hero from './components/Hero';
import About from './components/About';
import Solutions from './components/Solutions';
import Features from './components/Features';
import Advantages from './components/Advantages';
import DashboardPreview from './components/DashboardPreview';
import Team from './components/Team';
import Contact from './components/Contact';
import Footer from './components/Footer';

const App: React.FC = () => {
  return (
    <div className="App">
      <Header />
      <div className="main-container">
        <Hero />
        <Solutions />
        <DashboardPreview />
        <Features />
        {/* <Advantages /> */}
        {/* <Team /> */}
        <About />
        <Contact />
      </div>
      <Footer />
    </div>
  );
};

export default App;