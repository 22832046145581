import React from 'react';

const About: React.FC = () => {
  return (
    <section id="about" className="about">
      <h2>About SenseAI</h2>
      <p>SenseAI is a spin-off of HKU AIoT Lab, aiming to apply AIoT technologies to become a leading platform for smart buildings and smart eldercare.</p>
      <ul>
        <li>Applying cutting-edge AIoT technologies</li>
        <li>Patentable technologies supported by the NAM Healthy Longevity Catalyst Award (Hong Kong)</li>
        <li>Provisional US patents</li>
        <li>Focus on privacy-preserving human behavioral sensing</li>
      </ul>
    </section>
  );
};

export default About;